import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Invoicing terms/ Brands payment timelines`}</h2>
    <h3>{`What are the invoicing terms and flow for Fidel Marketplace offers? What is the process and timelines on the brands paying Fidel and the Publisher?`}</h3>
    <p>{`Please connect with your Sales representative or your Client Success Manager to share the detailed flow with you.`}</p>
    <h2>{`Billing`}</h2>
    <p>{`Any questions in regards to billing, please connect with your Client Success Manager or Sales representative.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      